import SectionTitle from './section-title';
import Feature from './feature';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from 'react-accessible-accordion';

export default function FeaturesAccordion({ features, title, description }) {
    return (
        <div className="flex flex-col my-10 ">
            {(title || description) && (
                <SectionTitle title={title} description={description} className="w-full"></SectionTitle>
            )}
            <div className="w-full mt-10 px-10">
                <Accordion allowZeroExpanded={true} preExpanded={[`uuid-${features[0].id.toString()}`]}>
                    {features.map((f) => (
                        <AccordionItem uuid={`uuid-${f.id.toString()}`} key={`acc-item-${f.id.toString()}`}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {f.icon && (
                                        <i
                                            className={`flex flaticon-${f.icon.icon} text-${f.icon.style} text-6xl `}
                                        ></i>
                                    )}
                                    <h3 className="font-normal text-base inline-block">{f.title}</h3>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <span
                                    className={`block mb-4`}
                                    dangerouslySetInnerHTML={{ __html: f.content }}
                                ></span>
                            </AccordionItemPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </div>
        </div>
    );
}
