import SectionTitle from './section-title';
import Feature from './feature';
export default function FeaturesTwoColumns({
    features,
    title,
    description,
    featureListTitleCss,
    featureTitleCss,
    featureContentCss,
    featureCss,
    titleTag
}) {
    return (
        <>
            {(title || description) && (
                <SectionTitle title={title} description={description} className={`w-full mt-10 section-title ${featureListTitleCss}`}></SectionTitle>
            )}
            <div className="grid gap-10 grid-cols-1 md:grid-cols-2 px-10 md:mt-10">
                {features.map((f) => (
                    <Feature
                        key={f.id}
                        feature={f}
                        featureCss={featureCss}
                        featureTitleCss={featureTitleCss}
                        featureContentCss={featureContentCss}
                        titleTag={titleTag}
                    ></Feature>
                ))}
            </div>
        </>
    );
}
