import { IoCloseOutline } from "react-icons/io5";
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

const Modal = ({ content, isOpen, setIsOpen }) => {

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => setIsOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-80" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="modal w-full max-w-screen-xl transform overflow-visible bg-white rounded-2xl bg-transaparent p-0 text-left align-middle shadow-xl transition-all">
                                <button className="absolute left-auto top-2 right-2 text-3xl" onClick={() => setIsOpen(false)}>
                                    <IoCloseOutline arial-label="Close modal" />
                                </button>
                                <div className="p-8 lg:p-16" dangerouslySetInnerHTML={{ __html: content }}></div>
                            </Dialog.Panel>

                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default Modal;