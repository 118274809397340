import FeaturesGrid from './features-grid';
import FeaturesGridLight from './features-grid-light';
import FeaturesOneColumn from './features-one-column';
import FeaturesTwoColumns from './features-two-columns';
import FeaturesThreeColumns from './features-three-columns';
import FeaturesFourColumns from './features-four-columns';
import FeaturesAccordion from './features-accordion';
import FeaturesAnimated from './features-animated';

export default function Features(props) {

    return (
        <section className={`features-box ${props.featureListCss ? props.featureListCss : ''}`}>
            {props.featureListCss === "bg-net" && (
                <div className='skew-top'></div>
            )}
            <div className='features-box-wrapper'>
                {props.style === 'animated' && <FeaturesAnimated {...props}></FeaturesAnimated>}
                {props.style === 'grid' && <FeaturesGrid {...props}></FeaturesGrid>}
                {props.style === 'grid-light' && <FeaturesGridLight {...props}></FeaturesGridLight>}
                {props.style === 'one-column' && <FeaturesOneColumn {...props}></FeaturesOneColumn>}
                {props.style === 'two-columns' && <FeaturesTwoColumns {...props}></FeaturesTwoColumns>}
                {props.style === 'three-columns' && <FeaturesThreeColumns {...props}></FeaturesThreeColumns>}
                {props.style === 'four-columns' && <FeaturesFourColumns {...props}></FeaturesFourColumns>}
                {props.style === 'accordion' && <FeaturesAccordion {...props}></FeaturesAccordion>}
            </div>
        </section>
    );
}
