import React, { useRef, useState } from 'react';
import { motion, useMotionValue, useTransform, AnimatePresence } from 'framer-motion';


const HoverEffect = ({ children }) => {
    const containerRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    const x = useMotionValue(0);
    const y = useMotionValue(0);
    const initialX = useMotionValue(0);
    const initialY = useMotionValue(0);
    const rotateX = useTransform(isHovered ? y : initialY, [-50, 50], ['-15deg', '15deg'], {
        clamp: false
    });
    const rotateY = useTransform(isHovered ? x : initialX, [-50, 50], ['15deg', '-15deg'], { clamp: false });

    const handleMouseMove = (event) => {
        const container = containerRef.current;
        if (container) {
            const { left, top, width, height } = container.getBoundingClientRect();
            const mouseX = event.clientX - left;
            const mouseY = event.clientY - top;
            const percentX = (mouseX / width - 0.5) * 100;
            const percentY = (mouseY / height - 0.5) * 100;
            x.set(percentX);
            y.set(percentY);
        }
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <motion.div
            className="hover-effect-container"
            ref={containerRef}
            onMouseMove={handleMouseMove}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ perspective: '1000px' }}
        >
            <AnimatePresence>
                <motion.div
                    key="content"
                    className="hover-effect-content"
                    style={{
                        rotateX,
                        rotateY,
                        transformStyle: 'preserve-3d',
                        transition: 'all 0.3s ease'
                    }}
                    initial={{ rotateX: 0, rotateY: 0 }}
                    animate={{ rotateX: `${rotateX}deg`, rotateY: `${rotateY}deg` }}
                    exit={{ rotateX: 0, rotateY: 0 }}
                >
                    {children}
                </motion.div>
            </AnimatePresence>
        </motion.div>
    );
};

export default HoverEffect;
