export default function SectionTitle({ title, description, style, className }) {
    return (
        <div className={`${className} flex flex-col px-10 relative z-20  section-title`}>
            <div className="flex flex-row section-title-wrapper">
                <i className={`${style ? style : ''} flaticon-down-arrow text-5xl opacity-50 flex mr-2`}></i>
                <span
                    className={`${style ? style : ''} mr-2 uppercase`}
                    dangerouslySetInnerHTML={{ __html: title }}
                ></span>
            </div>
            <span
                className={`full mt-2 text-normal`}
                dangerouslySetInnerHTML={{ __html: description }}
            ></span>
        </div>
    );
}
