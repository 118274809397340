import SectionTitle from './section-title';

export default function FeaturesGridLight({
    features,
    title,
    description,
    featureTitleCss,
    featureContentCss,
    featureCss,
    titleTag = 'h3'
}) {
    return (
        <>
            {(title || description) && (
                <SectionTitle
                    title={title}
                    description={description}
                    className="w-full lg:w-2/3  mt-20"
                ></SectionTitle>
            )}
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 p-10 md:pb-0 gap-10 w-full  ml-auto text-center ">
                {features.map((entry) => (
                    <div
                        key={entry.id.toString()}
                        className={`pt-0 flex flex-col items-center ${featureCss ?? featureCss}`}
                    >
                        <i
                            className={`flex flaticon-${entry.icon.icon} text-${entry.icon.style} text-6xl `}
                        ></i>
                        <div className="flex flex-col pt-4">
                            <p
                                className={`font-normal text-opacity-100 block ${featureTitleCss ? featureTitleCss : ''
                                    }`}
                            >
                                {entry.title}
                            </p>
                            {entry.content && (
                                <span
                                    className={`block mb-4 opacity-50 ${featureContentCss ?? featureContentCss
                                        }`}
                                    dangerouslySetInnerHTML={{ __html: entry.content }}
                                ></span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
