import SectionTitle from './section-title';
import Feature from './feature';
import HoverEffect from './hover-effect';
export default function FeaturesThreeColumns({
    features,
    title,
    description,
    featureListTitleCss,
    featureListCss,
    featureTitleCss,
    featureContentCss,
    featureCss,
    titleTag
}) {
    return (
        <>
            {(title || description) && (
                <SectionTitle key={'f-section-title'} title={title} description={description} className={`w-full mt-10 section-title ${featureListTitleCss}`}></SectionTitle>
            )}
            <div className="grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-10 md:mt-10">
                {features.map((f) => (
                    <>
                        {featureCss && featureCss.includes("box") && (
                            <HoverEffect key={`anim-feat-${f.id}`}>
                                <Feature
                                    feature={f}
                                    featureCss={featureCss}
                                    featureTitleCss={featureTitleCss}
                                    featureContentCss={featureContentCss}
                                    titleTag={titleTag}
                                ></Feature>
                            </HoverEffect>
                        )}
                        {(!featureCss || (featureCss && !featureCss.includes("box"))) && (
                            <Feature
                                key={`feat-${f.id}`}
                                feature={f}
                                featureCss={featureCss}
                                featureTitleCss={featureTitleCss}
                                featureContentCss={featureContentCss}
                                titleTag={titleTag}
                            ></Feature>
                        )}
                    </>
                ))}
            </div>
        </>
    );
}
