import Modal from "./modal";
import { IoChevronForward } from "react-icons/io5";
import React from "react";

export default function Feature({ feature, featureListCss, featureCss, featureTitleCss, featureContentCss, titleTag }) {
    titleTag = titleTag ? titleTag : 'h3';
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <div className={`flex feature-item gap-4 mb-4 ${featureCss ? featureCss : ''}`}>
            {feature.icon && (
                <i className={`flex flaticon-${feature.icon.icon} text-${feature.icon.style} text-6xl `}></i>
            )}
            <div className="flex flex-col feature-item-wrapper">
                {titleTag === 'h3' && (
                    <h3 className={`block ${featureTitleCss ? featureTitleCss : ''}`}>{feature.title}</h3>
                )}
                {titleTag === 'strong' && (
                    <strong className={`block text-trust-500 ${featureTitleCss ? featureTitleCss : ''}`}>
                        {feature.title}
                    </strong>
                )}
                {titleTag === 'p' && (
                    <p
                        className={`block text-trust-500 font-semibold ${featureTitleCss ? featureTitleCss : ''
                            }`}
                    >
                        {feature.title}
                    </p>
                )}
                {titleTag === 'span' && (
                    <span
                        className={`block text-trust-500 font-semibold ${featureTitleCss ? featureTitleCss : ''
                            }`}
                    >
                        {feature.title}
                    </span>
                )}
                {feature.content && (
                    <span
                        className={`block feature-content mb-4 ${featureContentCss ? featureContentCss : ''}`}
                        dangerouslySetInnerHTML={{ __html: feature.content }}
                    ></span>
                )}

                {feature.expandableContent && (
                    <>
                        <button onClick={() => setIsOpen(true)}
                            className="btn action-border block mx-auto"> <IoChevronForward className="inline-block" />
                            {feature.expandButtonLabel ? feature.expandButtonLabel : 'Read more'}
                        </button>
                        <Modal content={feature.expandableContent} isOpen={isOpen} setIsOpen={setIsOpen} />
                    </>
                )}
            </div>
        </div>
    );
}
