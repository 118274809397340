export function Merge(glob, page) {
    const newObj = { ...glob };

    for (var key in page) {
        if (typeof page[key] === 'object') {
            if (key === 'metaImage') {
                if (page[key].data !== null) {
                    newObj[key] = page[key];
                }
            } else if (key === 'metaSocial') {
                if (page[key].length > 0) {
                    newObj[key] = page[key];
                }
            } else {
                newObj[key] = page[key];
            }
        } else if (typeof page[key] === 'array') {
            if (page[key].length) {
                newObj[key] = page[key];
            }
        } else {
            if (page[key]) {
                newObj[key] = page[key];
            }
        }
    }

    return newObj;
}

export const estimateTime = (content) => {
    const text = content.replace(/(<([^>]+)>)/gi, '');
    const words = text.split(' ');
    return Math.ceil(words.length / 200);
};

export const formatDate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
};

export const uniqueArray = (arrayData) => {
    return arrayData.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
};

export const sortObjectByKey = (list, key, subKey = null, order = 'asc') => {
    if (subKey) {
        if (order === 'asc') {
            return list.sort((a, b) =>
                a[key][subKey] > b[key][subKey] ? 1 : b[key][subKey] > a[key][subKey] ? -1 : 0
            );
        } else {
            return list.sort((a, b) =>
                a[key][subKey] < b[key][subKey] ? 1 : b[key][subKey] < a[key][subKey] ? -1 : 0
            );
        }
    } else {
        if (order === 'asc') {
            return list.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));
        } else {
            return list.sort((a, b) => (a[key] < b[key] ? 1 : b[key] < a[key] ? -1 : 0));
        }
    }
};

export const getFirstLastName = (fullName = '') => {
    const name = fullName.trim();
    if (!name.length) {
        return {
            firstname: '',
            lastname: ''
        };
    }

    var splitName = name.split(' ');
    var firstName = splitName[0];
    var lastName = splitName.length > 1 ? splitName[splitName.length - 1] : '';

    return {
        firstname: firstName,
        lastname: lastName
    };
};
const defaultReplaceMap = {
    '[[[': '{{{',
    ']]]': '}}}',
    '[[': '{{',
    ']]': '}}'
};
export const replaceValuesInString = (str, replaceMap = defaultReplaceMap) => {
    let pattern, replacer;
    for (const [key, value] of Object.entries(replaceMap)) {
        pattern = new RegExp(key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
        replacer = value;
        str = str.replace(pattern, replacer);
    }
    return str;
};

export const setEmailVars = (text) => {
    return text
        .replace(/\[\[\[/g, '{{{')
        .replace(/\]\]\]/g, '}}}')
        .replace(/\[\[/g, '{{')
        .replace(/\]\]/g, '}}');
};

export const nextGivenDay = (day, hour, toISO = true) => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const today = new Date();
    let targetDay = today.getDay();

    while (daysOfWeek[targetDay] !== day) {
        targetDay = (targetDay + 1) % 7;
    }

    let nextDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + ((targetDay + 7 - today.getDay()) % 7)
    );
    nextDate.setHours(hour);

    if (nextDate <= today) {
        nextDate.setDate(nextDate.getDate() + 7);
    }
    if (toISO) {
        return nextDate.toISOString();
    } else {
        nextDate;
    }
};
