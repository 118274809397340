import SectionTitle from './section-title';
import Feature from './feature';
export default function FeaturesOneColumn({
    features,
    title,
    description,
    featureTitleCss,
    featureContentCss,
    featureCss,
    titleTag
}) {
    return (
        <div className="flex flex-col md:flex-row mt-20 f-onecolumn">
            {(title || description) && (
                <SectionTitle
                    className="flex w-full md:w-1/2 pb-8"
                    title={title}
                    description={description}
                ></SectionTitle>
            )}
            <div className="flex w-full flex-col md:w-1/2 px-10 ">
                {features.map((f) => (
                    <Feature
                        key={f.id}
                        feature={f}
                        featureCss={featureCss}
                        featureTitleCss={featureTitleCss}
                        featureContentCss={featureContentCss}
                        titleTag={titleTag}
                    ></Feature>
                ))}
            </div>
        </div>
    );
}
